.sponsor-image {
    cursor: pointer;
    margin: 0px 40px 0px 40px;
    height: 50px;
}


.sliding-text {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    bottom: 0;
    cursor: pointer;
    margin: 10px 0 12px 0;
    z-index: 2;
    position: relative;
}

.sliding-text ul {
    display: inline-block;
    white-space: nowrap;
    margin-block: 0;
    padding-inline: 0;
}

.sliding-text li {
    display: inline-block;
    padding-left: 54px;
    position: relative;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: white;
}

.sliding-text li::before {
    content: '';
    position: absolute;
    width: 11px;
    height: 11px;
    background: rgb(242, 255, 0);
    margin-left: -34px;
    margin-top: 3px;
    transform: rotate(45deg)
}