/* Styling */
@media screen and (min-width: 200px) and (max-width: 479px) {
  .timeline-event {
    position: relative;
  }

  .timeline-event-copyR {
    position: relative;
    right: 0%;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  .timeline-event-copyL {
    position: relative;
    text-align: right;
    left: 0%;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  .timeline-event:hover .timeline-event-iconR {
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: #fdc716;
  }

  .timeline-event:hover .timeline-event-thumbnailR {
    -moz-box-shadow: inset 40em 0 0 0 #fdc716;
    -webkit-box-shadow: inset 40em 0 0 0 #fdc716;
    box-shadow: inset 40em 0 0 0 #fdc716;
  }

  .timeline-event-iconR {
    -moz-transition: -moz-transform 0.2s ease-in;
    -o-transition: -o-transform 0.2s ease-in;
    -webkit-transition: -webkit-transform 0.2s ease-in;
    transition: transform 0.2s ease-in;
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: rgb(255, 255, 255);
    outline: 5 solid rgb(0, 0, 0);
    display: block;
    margin: 0.5em 0.5em 0.5em -0.5em;
    position: absolute;
    top: 0;
    left: 50.5%;
    width: 1.5em;
    height: 1.5em;
    border-radius: 0.5em;
  }

  .timeline-event-thumbnailR {
    -moz-transition: box-shadow 0.5s ease-in 0.1s;
    -o-transition: box-shadow 0.5s ease-in 0.1s;
    -webkit-transition: box-shadow 0.5s ease-in;
    -webkit-transition-delay: 0.1s;
    transition: box-shadow 0.5s ease-in 0.1s;
    color: rgb(0, 0, 0);
    font-size: 1.2em;
    background-color: rgb(255, 255, 255);
    border-radius: 11px;
    -moz-box-shadow: inset 0 0 0 0em #fd8d16;
    -webkit-box-shadow: inset 0 0 0 0em #fdac16;
    box-shadow: inset 0 0 0 0em #fdc716;
    display: inline-block;
    margin-bottom: 1.2em;
    padding: 0.25em 1em 0.2em 1em;
  }

  .timeline-event:hover .timeline-event-iconL {
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: #fa8d01;
  }

  .timeline-event:hover .timeline-event-thumbnailL {
    -moz-box-shadow: inset 40em 0 0 0 #fa8d01;
    -webkit-box-shadow: inset 40em 0 0 0 #fa8d01;
    box-shadow: inset 40em 0 0 0 #fa8d01;
  }

  .timeline-event-iconL {
    -moz-transition: -moz-transform 0.2s ease-in;
    -o-transition: -o-transform 0.2s ease-in;
    -webkit-transition: -webkit-transform 0.2s ease-in;
    transition: transform 0.2s ease-in;
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: rgb(255, 255, 255);
    outline: 10px solid rgb(0, 0, 0);
    display: block;
    margin: 0.5em 0.5em 0.5em -0.5em;
    position: absolute;
    top: 0;
    left: 50.5%;
    width: 1.5em;
    height: 1.5em;
    border-radius: 0.5em;
  }

  .timeline-event-thumbnailL {
    -moz-transition: box-shadow 0.5s ease-in 0.1s;
    -o-transition: box-shadow 0.5s ease-in 0.1s;
    -webkit-transition: box-shadow 0.5s ease-in;
    -webkit-transition-delay: 0.1s;
    transition: box-shadow 0.5s ease-in 0.1s;
    color: rgb(0, 0, 0);
    font-size: 1.2em;
    border-radius: 11px;
    background-color: rgb(255, 255, 255);
    -moz-box-shadow: inset 0 0 0 0em #ef795a;
    -webkit-box-shadow: inset 0 0 0 0em #ef795a;
    box-shadow: inset 0 0 0 0em #ef795a;
    display: inline-block;
    margin-bottom: 1.2em;
    padding: 0.25em 1em 0.2em 1em;
  }
}

@media screen and (min-width: 480px) and (max-width: 750px) {
  .timeline:before {
    background-color: rgb(255, 255, 255);
  }

  .timeline-event {
    position: relative;
  }

  .timeline-event-copyR {
    position: relative;
    right: 0%;
    margin-top: 1rem;
  }

  .timeline-event-copyL {
    position: relative;
    text-align: right;
    left: 0%;
  }

  .timeline-event:hover .timeline-event-iconR {
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: #fdc716;
  }

  .timeline-event:hover .timeline-event-thumbnailR {
    -moz-box-shadow: inset 40em 0 0 0 #fdc716;
    -webkit-box-shadow: inset 40em 0 0 0 #fdc716;
    box-shadow: inset 40em 0 0 0 #fdc716;
  }

  .timeline-event-iconR {
    -moz-transition: -moz-transform 0.2s ease-in;
    -o-transition: -o-transform 0.2s ease-in;
    -webkit-transition: -webkit-transform 0.2s ease-in;
    transition: transform 0.2s ease-in;
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: rgb(255, 255, 255);
    outline: 5px solid rgb(0, 0, 0);
    display: block;
    margin: 0.5em 0.5em 0.5em -0.5em;
    position: absolute;
    top: 0;
    left: 50.5%;
    width: 1.5em;
    height: 1.5em;
    border-radius: 0.5em;
  }

  .timeline-event-thumbnailR {
    -moz-transition: box-shadow 0.5s ease-in 0.1s;
    -o-transition: box-shadow 0.5s ease-in 0.1s;
    -webkit-transition: box-shadow 0.5s ease-in;
    -webkit-transition-delay: 0.1s;
    transition: box-shadow 0.5s ease-in 0.1s;
    color: rgb(0, 0, 0);
    font-size: 1.2em;
    background-color: rgb(255, 255, 255);
    border-radius: 11px;
    -moz-box-shadow: inset 0 0 0 0em #fd8d16;
    -webkit-box-shadow: inset 0 0 0 0em #fdac16;
    box-shadow: inset 0 0 0 0em #fdc716;
    display: inline-block;
    margin-bottom: 1.2em;
    padding: 0.25em 1em 0.2em 1em;
  }

  .timeline-event:hover .timeline-event-iconL {
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: #fa8d01;
  }

  .timeline-event:hover .timeline-event-thumbnailL {
    -moz-box-shadow: inset 40em 0 0 0 #fa8d01;
    -webkit-box-shadow: inset 40em 0 0 0 #fa8d01;
    box-shadow: inset 40em 0 0 0 #fa8d01;
  }

  .timeline-event-iconL {
    -moz-transition: -moz-transform 0.2s ease-in;
    -o-transition: -o-transform 0.2s ease-in;
    -webkit-transition: -webkit-transform 0.2s ease-in;
    transition: transform 0.2s ease-in;
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: rgb(255, 255, 255);
    outline: 10px solid rgb(0, 0, 0);
    display: block;
    margin: 0.5em 0.5em 0.5em -0.5em;
    position: absolute;
    top: 0;
    left: 50.5%;
    width: 1.5em;
    height: 1.5em;
    border-radius: 0.5em;
  }

  .timeline-event-thumbnailL {
    -moz-transition: box-shadow 0.5s ease-in 0.1s;
    -o-transition: box-shadow 0.5s ease-in 0.1s;
    -webkit-transition: box-shadow 0.5s ease-in;
    -webkit-transition-delay: 0.1s;
    transition: box-shadow 0.5s ease-in 0.1s;
    color: rgb(0, 0, 0);
    font-size: 1.2em;
    border-radius: 11px;
    background-color: rgb(255, 255, 255);
    -moz-box-shadow: inset 0 0 0 0em #ef795a;
    -webkit-box-shadow: inset 0 0 0 0em #ef795a;
    box-shadow: inset 0 0 0 0em #ef795a;
    display: inline-block;
    margin-bottom: 1.2em;
    padding: 0.25em 1em 0.2em 1em;
  }
}

@media screen and (min-width: 750px) {
  .timeline {
    margin: 4em auto;
    position: relative;
    max-width: 46em;
  }

  .timeline:before {
    background-color: rgb(255, 255, 255);
    content: '';
    margin-left: -1px;
    position: absolute;
    top: 0;
    left: 51%;
    width: 2px;
    height: 100%;
  }

  .timeline-event {
    position: relative;
  }

  .timeline-event::marker {
    color: rgb(0, 0, 0) !important;
  }

  .timeline-event:hover .timeline-event-iconR {
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: #fdc716;
  }

  .timeline-event:hover .timeline-event-thumbnailR {
    -moz-box-shadow: inset 40em 0 0 0 #fdc716;
    -webkit-box-shadow: inset 40em 0 0 0 #fdc716;
    box-shadow: inset 40em 0 0 0 #fdc716;
  }

  .timeline-event-copyR {
    padding: 2em 1em 2em 2em;
    position: relative;
    top: -1.875em;
    /* left: 4em; */
    left: 53%;
    width: 80%;
    background-color: transparent;
    max-width: fit-content;
  }

  .timeline-event-iconR {
    -moz-transition: -moz-transform 0.2s ease-in;
    -o-transition: -o-transform 0.2s ease-in;
    -webkit-transition: -webkit-transform 0.2s ease-in;
    transition: transform 0.2s ease-in;
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: rgb(255, 255, 255);
    outline: 10px solid rgb(0, 0, 0);
    display: block;
    margin: 0.5em 0.5em 0.5em -0.5em;
    position: absolute;
    top: 0;
    left: 50.5%;
    width: 1.5em;
    height: 1.5em;
    border-radius: 0.5em;
  }

  .timeline-event-thumbnailR {
    -moz-transition: box-shadow 0.5s ease-in 0.1s;
    -o-transition: box-shadow 0.5s ease-in 0.1s;
    -webkit-transition: box-shadow 0.5s ease-in;
    -webkit-transition-delay: 0.1s;
    transition: box-shadow 0.5s ease-in 0.1s;
    color: rgb(0, 0, 0);
    font-size: 1.2em;
    background-color: rgb(255, 255, 255);
    border-radius: 11px;
    -moz-box-shadow: inset 0 0 0 0em #fd8d16;
    -webkit-box-shadow: inset 0 0 0 0em #fdac16;
    box-shadow: inset 0 0 0 0em #fdc716;
    display: inline-block;
    margin-bottom: 1.2em;
    padding: 0.25em 1em 0.2em 1em;
  }

  /* -----------SOL-------------- */

  .timeline-event:hover .timeline-event-iconL {
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: #fa8d01;
  }

  .timeline-event:hover .timeline-event-thumbnailL {
    -moz-box-shadow: inset 40em 0 0 0 #fa8d01;
    -webkit-box-shadow: inset 40em 0 0 0 #fa8d01;
    box-shadow: inset 40em 0 0 0 #fa8d01;
  }

  .timeline-event-copyL {
    padding: 2em 1em 2em 2em;
    position: relative;
    top: -1.875em;
    /* left: 4em; */
    left: -33%;
    width: 80%;
    text-align: right;
    background-color: transparent;
    overflow: hidden;
  }

  .timeline-event-iconL {
    -moz-transition: -moz-transform 0.2s ease-in;
    -o-transition: -o-transform 0.2s ease-in;
    -webkit-transition: -webkit-transform 0.2s ease-in;
    transition: transform 0.2s ease-in;
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: rgb(255, 255, 255);
    outline: 10px solid rgb(0, 0, 0);
    display: block;
    margin: 0.5em 0.5em 0.5em -0.5em;
    position: absolute;
    top: 0;
    left: 50.5%;
    width: 1.5em;
    height: 1.5em;
    border-radius: 0.5em;
  }

  .timeline-event-thumbnailL {
    -moz-transition: box-shadow 0.5s ease-in 0.1s;
    -o-transition: box-shadow 0.5s ease-in 0.1s;
    -webkit-transition: box-shadow 0.5s ease-in;
    -webkit-transition-delay: 0.1s;
    transition: box-shadow 0.5s ease-in 0.1s;
    color: rgb(0, 0, 0);
    font-size: 1.2em;
    border-radius: 11px;
    background-color: rgb(255, 255, 255);
    -moz-box-shadow: inset 0 0 0 0em #ef795a;
    -webkit-box-shadow: inset 0 0 0 0em #ef795a;
    box-shadow: inset 0 0 0 0em #ef795a;
    display: inline-block;
    margin-bottom: 1.2em;
    padding: 0.25em 1em 0.2em 1em;
  }
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1280px) {
}

@media screen and (min-width: 1536px) {
}
