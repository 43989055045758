.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-prev,
.swiper-button-next {
  color: #ffb800;
}

.swiper-pagination-bullet-active-prev {
  background-color: #ffb800 !important;
}

.swiper-pagination-bullet-active-prev-prev {
  background-color: #fffa2b !important;
}

.swiper-pagination-bullet-active {
  background-color: #fffa2b !important;
}

.swiper-pagination-bullet-active-next {
  background-color: #fffa2b !important;
}

.swiper-pagination-bullet-active-next-next {
  background-color: #fffa2b !important;
}

.swiper button {
  border-radius: 20px;
  height: auto;
  width: 100;
  font-size: 14px;
  border: solid 1px rgb(194, 194, 194);

  color: black;
}

.swiper button:hover {
  background-color: #fffa2b;
  border: solid 1px rgb(78, 78, 78);
}
