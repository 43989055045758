@media screen and (min-width: 561px) {
  .cards {
    margin: 0 auto;
    text-align: center;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .content {
    background: radial-gradient(
      circle,
      rgba(71, 71, 71, 0.30024509803921573) 0%,
      rgba(0, 0, 0, 0.5243347338935574) 1.1%
    );
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 160px;
    width: 230px;
    margin: 10px;
    padding: 10px 50px;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 20px;
    transition: all 0.3s ease;
  }

  .content a {
    background: transparent;
    color: white;
    font-weight: 600;
    font-size: 12px;
    text-decoration: none;
    border: solid 1px rgb(194, 194, 194);
    padding: 7px 20px;
    border-radius: 20px;
    transition: 0.3s ease;
  }

  .content:hover {
    border-color: #fffa2b;
  }

  .content > a:hover {
    color: white;
    border: solid 1px #fffa2b;
    background-color: #fffd8d55;
  }

  .content h1 {
    font-size: 20px;
    font-weight: 600;
    margin: 0px 0;
    letter-spacing: 1px;
    color: white;
  }
}

@media screen and (max-width: 560px) {
  .cards {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .content {
    background: radial-gradient(
      circle,
      rgba(71, 71, 71, 0.30024509803921573) 0%,
      rgba(0, 0, 0, 0.5243347338935574) 1.1%
    );
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    width: 100%;
    border-radius: 20px;
    transition: all 0.3s ease;
    margin-bottom: 12px;
    padding: 0px 13px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0);
    transition: all 0.3s ease;
  }

  .content a {
    background: transparent;
    color: white;
    font-weight: 600;
    font-size: 12px;
    text-decoration: none;
    border: solid 1px rgb(194, 194, 194);
    padding: 7px 20px;
    border-radius: 20px;
    transition: 0.3s ease;
  }

  .content:hover {
    border-color: #fffa2b;
  }

  .content > a:hover {
    color: white;
    border: solid 1px #fffa2b;
    background-color: #fffd8d55;
  }

  .content h1 {
    font-size: 20px;
    margin: 0px 0;
    letter-spacing: 1px;
    text-align: center;
    color: white;
  }
}
