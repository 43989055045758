.rocketastronaut {
  z-index: 1;
  left: -20px;
  top: 240px;
  width: 300px;
  height: 400px;
  display: block;
  position: absolute;
}

.rocket {
  z-index: 1;
  right: 75px;
  top: 270px;
  width: 200px;
  height: 320px;
  display: block;
  position: absolute;
}

.saturn {
  z-index: 1;
  left: 900px;
  top: 10px;
  width: 180px;
  height: 142px;
  display: block;
  position: absolute;
}

.cloud1 {
  z-index: 1;
  left: 200px;
  top: 25;
  width: 122px;
  height: 100px;
  display: block;
  position: absolute;
}
.cloud2 {
  z-index: 1;
  left: 50px;
  top: 35px;
  width: 150px;
  height: 122px;
  display: block;
  position: absolute;
}
