.buttonShadow {
    box-shadow: 0 8px 16px rgb(102, 80, 14);

}

.buttonShadow:hover {
    box-shadow: 0 8px 22px rgba(194, 174, 24, 0.43);
    transition: box-shadow .3s ease-in-out;

}

.gameCard {
    position: relative;
    box-shadow: 0px 2px 4px rgba(142, 142, 142, 0.2);
    border-radius: 20px;
    border: solid 1px;
    border-color: transparent;
    background: radial-gradient(circle, rgba(71, 71, 71, 0.30024509803921573) 0%, rgba(0, 0, 0, 0.5243347338935574) 91.1%);
    transition: all .3s ease;
}

.gameCard:hover {
    border-color: #fffa2b;
    transition: all .8s ease;
}

@media screen and (max-width:320px) {
    .buttonShadow {
        min-height: 45px;
        margin-bottom: 10px;
        position: absolute;
    }

    .gameCard {
        min-height: 450px;
    }
}

@media screen and (max-width:450px) {
    .buttonShadow {
        min-height: 45px;
        margin-bottom: 10px;
        position: absolute;
    }

    .gameCard {
        min-height: 450px;
    }
}