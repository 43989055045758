.legal-section {
  top: 5%;
  bottom: 5%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.legal-wrapper {
  width: 90%;
  margin: 0 auto;
}

.legal-header {
  text-align: left;
  color: white;
  font-size: 4em;
  margin-top: 3%;
}

.text-wrapper {
  color: white;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.tg {
  border-collapse: collapse;
  border-spacing: 0;
}

.tg td {
  border: 1px solid #dddddd;
  overflow: hidden;
  word-break: normal;
}

.tg th {
  border: 1px solid #dddddd;
  overflow: hidden;
  word-break: normal;
}

.tg .tg-cly1 {
  text-align: left;
  vertical-align: middle;
}

.ul-header {
  font-size: 1.2em;
  font-weight: 900;
}

.legal-link {
  text-decoration: none;
  color: #fffa2b;
}

@media only screen and (max-width: 424px) {
  .legal-header {
    font-size: 2em;
    margin-top: 25%;
  }
}

.links-section {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.links-section h1 {
  font-size: 4rem;
  font-family: 'Calamity';
  color: white;
  letter-spacing: 5px;
  position: relative;
  margin-top: 50px;
  margin-bottom: 20px;
}

.links-section a {
  width: 450px;
  text-decoration: none;
  color: white;
  margin-bottom: 15px;
}

.link-item {
  height: 80px;
  border: 0.1px solid var(--border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  transition: 0.3s ease;
}

.link-title {
  margin-left: 3%;
}

.link-icon {
  margin-right: 3%;
}

.link-item:hover {
  transform: scale(1.05);
  transition: 0.3s ease;
  color: #fffa2b;
}

@media only screen and (max-width: 500px) {
  .links-section a {
    width: 90%;
  }
}
