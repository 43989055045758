/* * cookiebot */
.m-cookie {
  position: fixed;
  width: 100%;
  height: 24%;
  bottom: -2%;
  box-sizing: border-box;
  padding: 32px 0 22px;
  font-family: 'Urbanist';

  color: #fff;
  background: transparent;
  backdrop-filter: blur(10px);
  border-top: 1px solid #ffffff;
  z-index: 10;
}

@media only screen and (min-width: 768px) {
  .m-cookie__wrap {
    display: flex;
  }
}

.m-cookie .wrapper {
  position: relative;
  box-sizing: border-box;
  width: 90%;
  max-width: 1208px;
  margin: 0 auto;
}

.m-cookie__angle {
  display: none;
}

@media only screen and (min-width: 768px) {
  .m-cookie__angle {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
}

.m-cookie__left {
  box-sizing: border-box;
  max-width: 728px;
  margin-bottom: 24px;
}

@media only screen and (min-width: 768px) {
  .m-cookie__left {
    margin-bottom: 0;
    margin-right: 24px;
    padding-left: 70px;
  }
}

.m-cookie__right {
  box-sizing: border-box;
  display: flex;
  margin-left: auto;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .m-cookie__right {
    display: block;
  }
}

@media only screen and (min-width: 1024px) {
  .m-cookie__right {
    display: flex;
  }
}

.m-cookie__title {
  box-sizing: border-box;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 400;
  font-family: 'Urbanist';

  margin-top: 0;
  margin-bottom: 8px;
}

@media only screen and (min-width: 768px) {
  .m-cookie__title {
    font-size: 24px;
    line-height: 1.42;
  }
}

.m-cookie__text {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.57;
  margin: 0;
}

.m-cookie__text a {
  color: #fff;
  text-decoration: underline;
}

@media only screen and (min-width: 768px) {
  .m-cookie__text {
    font-size: 16px;
    line-height: 1.63;
  }
}

.m-cookie__button {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  width: 50%;
  max-width: 200px;
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  padding: 9px 20px 10px;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 50px;
  margin-right: 16px;
  background: transparent;
  backdrop-filter: blur(50px);
  margin-left: auto;
  z-index: 1;
  cursor: pointer;
  overflow: hidden;
}

.m-cookie__button:hover {
  border-color: #fffa2b;
}
@media only screen and (min-width: 768px) {
  .m-cookie__button {
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    line-height: 1.63;
    padding: 10px 20px 11px;
    margin-right: 24px;
    margin-bottom: 16px;
  }
}

.m-cookie__button::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 500%;
  min-width: 200px;
  height: 1000%;
  z-index: -1;
  transform-origin: 0% 0%;
  transform: translateX(-30%) translateY(10%) rotate(-22deg);
  transition: transform 1s;
  overflow: hidden;
}

.m-cookie__button:hover::after {
  transform: translateY(10%) translateX(50px) rotate(-15deg);
  background-color: #ffffff16;
}

@media only screen and (min-width: 1024px) {
  .m-cookie__button {
    margin-bottom: 0;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.m-cookie__button--secondary {
  color: #ffffff;
  border-color: #ffffff;
  background: transparent;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.m-cookie__button--secondary:hover {
  border-color: #fffa2b;
}

.m-cookie__button--secondary:hover::after {
  background-color: #3c3b0077;
}
