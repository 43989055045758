.gradientText {
    background: linear-gradient(90deg, #fffa2b 0%, rgb(255, 206, 82) 39%, rgb(255, 231, 171) 90%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 700;
    font-size: 3vw;
    font-family: 'Urbanist';
}

.typed-cursor {
    font-size: 3vw;
    font-weight: 700;
    opacity: 1;
    -webkit-animation: blink 0.7s infinite;
    -moz-animation: blink 0.7s infinite;
    animation: blink 0.7s infinite;
    background: linear-gradient(90deg, rgba(253, 187, 45, 1) 0%, #ffe092 49%, rgba(241, 241, 241, 1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

@keyframes blink {
    10% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes blink {
    10% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes blink {
    10% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (min-width: 200px) {
    .gradientText {
        font-size: 30px;
        min-height: 140px;
    }

    .typed-cursor {
        font-size: 30px;
    }
}


@media screen and (min-width: 480px) {
    .gradientText {
        font-size: 24px;
        min-height: 38px;
    }

    .typed-cursor {
        font-size: 20px;
    }
}

@media screen and (min-width: 768px) {
    .gradientText {
        font-size: 30px;
        min-height: 45px;
    }

    .typed-cursor {
        font-size: 25px;
    }
}

@media screen and (min-width: 992px) {
    .gradientText {
        font-size: 40px;
        min-height: 60px;
    }

    .typed-cursor {
        font-size: 35px;
    }
}

@media screen and (min-width: 1280px) {
    .gradientText {
        font-size: 60px;
        min-height: 90px;
    }

    .typed-cursor {
        font-size: 55px;
    }
}

@media screen and (min-width: 1536px) {
    .gradientText {
        font-size: 60px;
        min-height: 90px;
    }

    .typed-cursor {
        font-size: 55px;

    }
}